<template>
  <Head title="Lookalikes" />
  <div v-if="lookalikesData" class="flex justify-between">
    <div v-if="lookalikesData.archetypeData.name !== ''" class="mb-4 flex">
      <h1 class="mt-0 mb-0 self-center text-3xl blinker-semibold leading-9 text-slate-800">
        {{ lookalikesData.archetypeData.name }}
        <span class="font-light capitalize">({{ lookalikesData.archetypeData.position }})</span>
        <a :href="linkedinUrl" target="_blank" rel="noreferrer">
          <LinkedinIcon class="ml-2 inline h-[20px] w-[20px] align-middle" />
        </a>
      </h1>
    </div>
    <div class="flex gap-3">
      <SelectPastLookalikeSearch
        ref="com"
        text-for-button="Go"
        :selected-past-archetype="Number(route.params.searchId)"
        :button-on-the-same-level-as-input="true"
        @resume-search-results="resumeSearch"
        @resume-search-results-error="resumeSearchFailed = true"
        @loading-past-search-results="loading = true"
      />
      <BaseButton
        class="self-center !mr-0"
        text="Edit Search"
        bg-color="green"
        @click="showEditModal = true"
      />
      <RouterLink
        class="black-theme-white-link bg-primary-500 hover:bg-primary-400 self-center focus:outline-acne inline-flex h-9 items-center rounded px-3 text-sm font-bold text-white shadow ring-primary-200 last:mr-0 focus:ring dark:text-gray-800 dark:ring-gray-600"
        to="/"
        >New Search</RouterLink
      >
    </div>
    <Toaster
      v-if="isLocationFallback"
      message="We found less than 50 Lookalikes for your selected location. To provide richer results, Lookalikes from a bigger search radius are displayed as well."
      color="green"
      :is-constant="true"
      @hide-toaster="closeLocationFallbackMessage"
    />
    <Toaster
      v-if="resumeSearchFailed"
      message="Oops something went wrong. please contact our support team"
      color="red"
      :time="5000"
      @hide-toaster="resumeSearchFailed = false"
    />
  </div>
  <EditArchetypeModal
    v-if="showEditModal"
    @close="showEditModal = false"
    @is-location-fallback="(isFallback) => (isLocationFallback = isFallback)"
    @search-edited="refreshPastSearches"
  />
  <TabbedPage :tabs="tabs" :data="{ loading: loading }" />
</template>

<script setup lang="ts">
import { computed, markRaw, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import TabbedPage from '@/components/tabbedPage/TabbedPage.vue'
import AllLookalikes from './Tabs/AllLookalikes/AllLookalikes.vue'
import Shortlist from './Tabs/Shortlist/Shortlist.vue'
import SelectPastLookalikeSearch from '../LookalikeSearch/SelectPastLookalikeSearch.vue'
import LinkedinIcon from '@/components/icons/LinkedinIcon.vue'
import Toaster from '@/components/Toaster.vue'
import BaseButton from '@/components/BaseButton.vue'
import { update } from '@intercom/messenger-js-sdk'
import { useLookalikesStore } from '@/stores/lookalikes'
import EditArchetypeModal from './EditArchetypeModal.vue'

import type { Tabs } from '@/components/tabbedPage/interfaces.ts'

const com = ref()

const store = useLookalikesStore()
const route = useRoute()
const router = useRouter()

const tabs: Tabs = {
  'all-lookalikes': { displayName: 'All Lookalikes', component: markRaw(AllLookalikes) },
  shortlist: { displayName: 'Shortlist', component: markRaw(Shortlist) }
}

const resumeSearchFailed = ref(false)
const loading = ref(false)
const showEditModal = ref(false)
let intervalId = null

const lookalikesData = computed(() => store.searchResults)

const isLocationFallback = ref(
  lookalikesData.value && lookalikesData.value.isLocationFallback
    ? lookalikesData.value.isLocationFallback
    : null
)

const linkedinUrl = computed(
  () => `https://www.linkedin.com/in/${lookalikesData.value.archetypeData.profileId}`
)

const resumeSearch = (searchId: number) => {
  loading.value = false
  router.replace({ name: 'searchResults', params: { searchId: searchId } })
}

const closeLocationFallbackMessage = () => {
  isLocationFallback.value = false
  update({ hide_default_launcher: false })
}

onBeforeMount(async () => {
  if (!lookalikesData.value) {
    loading.value = true
    try {
      await store.fetchSearchResults(parseInt(route.params.searchId))
      store.setLookalikeCurrentHistorySearchId(parseInt(route.params.searchId))
    } catch {
      resumeSearchFailed.value = true
    }
    loading.value = false
  }
})

// fetch shortlisted candidates every 15 seconds to real-time update table
intervalId = setInterval(() => {
  store.fetchSearchResults(parseInt(route.params.searchId))
}, 30000)

onBeforeUnmount(() => {
  clearInterval(intervalId)
})

watch(
  () => isLocationFallback.value,
  () => {
    if (isLocationFallback.value) {
      update({ hide_default_launcher: true })
    }
  },
  { immediate: true }
)

const refreshPastSearches = () => {
  com.value.refreshPastSearches()
}
</script>
