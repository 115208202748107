<template>
  <div :class="{ flex: buttonOnTheSameLevelAsInput }">
    <SelectInput
      ref="pastSearchesSelect"
      class="mb-4 pl-0"
      :class="{ 'max-w-[600px]': buttonOnTheSameLevelAsInput }"
      :key-value-options="true"
      :option="selectedPastArchetype"
      :get-key-value-option="true"
      :api-url="pastSearchesUrl"
      title="select a lookalikes search"
      @option-selected="archetypeChanged"
    />
    <BaseButton
      v-if="showButton"
      class="w-fit cursor-pointer"
      :class="{ 'self-center': buttonOnTheSameLevelAsInput, 'ml-4': buttonOnTheSameLevelAsInput }"
      :text="textForButton"
      :loading="loading"
      :disabled="searchId === null || searchId === ''"
      @on-click="resumeSearch"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineExpose, ref } from 'vue'

import SelectInput from '@/components/inputs/SelectInput.vue'
import BaseButton from '@/components/BaseButton.vue'
import { useLookalikesStore } from '@/stores/lookalikes'

import { captureException } from '@sentry/vue'

interface IProps {
  textForButton: string
  buttonOnTheSameLevelAsInput: boolean
  selectedPastArchetype: string
  showButton: boolean
}

const store = useLookalikesStore()
const emit = defineEmits([
  'resumeSearchResults',
  'resumeSearchResultsError',
  'loadingPastSearchResults'
])

const props = defineProps<IProps>()

const searchId = ref<string | null>(null)
const selectedArchetype = ref('')
const loading = ref(false)
const pastSearchesSelect = ref(null)

const pastSearchesUrl = computed(
  () =>
    `${import.meta.env.VITE_LOOKALIKES_API}/api/look-alike/past-searches?timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
)

const archetypeChanged = (selectedArchetypeValue, searchIndex): void => {
  searchId.value = searchIndex

  selectedArchetype.value = selectedArchetypeValue
  if (!props.showButton) {
    resumeSearch()
  }
}

const resumeSearch = async (): Promise<void> => {
  loading.value = true
  emit('loadingPastSearchResults')

  try {
    await store.fetchSearchResults(parseInt(searchId.value))

    store.setLookalikeCurrentHistorySearchId(parseInt(searchId.value))

    emit('resumeSearchResults', searchId.value)
  } catch (error) {
    captureException(error)
    console.log(error)
    emit('resumeSearchResultsError')
  } finally {
    loading.value = false
  }
}

const refreshPastSearches = () => {
  pastSearchesSelect.value.getFilterOptions()
}

defineExpose({
  refreshPastSearches
})
</script>
