<template>
  <BaseButton
    :text="text"
    :loading="loading"
    :disabled="isShortlisted !== undefined ? isShortlisted : isShortlistedInState"
    :bg-color="
      (isShortlisted !== undefined ? isShortlisted : isShortlistedInState) ? 'darkBlue' : 'default'
    "
    @click="shortlist"
  />
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps, ref } from 'vue'
import BaseButton from '@/components/BaseButton.vue'

import { useLookalikesStore } from '@/stores/lookalikes'

import { captureException } from '@sentry/vue'
import axios from 'axios'

interface IProps {
  searchId: number
  archetypeLiProfileId: string
  candidateLiProfileId: string
  isShortlisted?: boolean
}

const emit = defineEmits(['shortlistFailed', 'shortlistSucceed'])
const props = defineProps<IProps>()
const store = useLookalikesStore()

const loading = ref(false)
const localShortlist = ref(false)

const isShortlistedInState = computed(() => store.shortlist.includes(props.candidateLiProfileId))

const text = computed(() =>
  (props.isShortlisted !== undefined ? props.isShortlisted : isShortlistedInState.value)
    ? 'Shortlisted'
    : 'Shortlist'
)

const shortlist = async () => {
  loading.value = true

  try {
    await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/look-alike/shortlist`, {
      searchId: props.searchId,
      candidateLiProfileId: props.candidateLiProfileId
    })

    localShortlist.value = true
    store.addCandidateLiIdToShortlist(props.candidateLiProfileId)
    emit('shortlistSucceed')
  } catch (error) {
    captureException(error)
    emit('shortlistFailed', error.response?.data.message)
  } finally {
    loading.value = false
  }
}
</script>
