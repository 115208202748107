<template>
  <div>
    <div class="p-4">
      <Input
        class="mb-4"
        title="ARCHETYPE LINKEDIN URL"
        placeholder="Linkedin URL"
        :value="linkedinUrl"
        :is-valid="isLiUrlValid"
        invalid-message="The provided Linkedin url is not valid"
        data-hj-allow
        @input-change="(value) => (linkedinUrl = value)"
      />
      <LocationFilter
        :show-recently-searched="true"
        @country-selected="countrySelected"
        @state-selected="stateSelected"
        @city-selected="citySelected"
        @states-options="(statesOptions) => $emit('statesOptions', statesOptions)"
        @cities-options="(citiesOptions) => $emit('citiesOptions', citiesOptions)"
      />

      <div class="mb-[20px]">
        <BaseButton
          class="mt-4"
          :loading="loading"
          type="button"
          text="Start Lookalikes Search"
          @on-click="search"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineEmits, defineProps } from 'vue'
import Input from '@/components/inputs/Input.vue'
import BaseButton from '@/components/BaseButton.vue'
import LocationFilter from './LocationFilter.vue'

import type { archetypeProfile } from '../interfaces.ts'

interface IProps {
  loading: boolean
  loadingRevisedSearch: boolean
  isNoResults: boolean
  archetypeCurrentPositionTitle: string
  suggestedPositionTitles: string[]
  archetypeProfileData: null | archetypeProfile
}

const emit = defineEmits([
  'searchLookalike',
  'countrySelected',
  'stateSelected',
  'citySelected',
  'searchRevisedLookalike',
  'getArchetypeProfileData',
  'statesOptions',
  'citiesOptions'
])

defineProps<IProps>()

const country = ref('')
const state = ref('')
const city = ref('')
const linkedinUrl = ref('')
const isLiUrlValid = ref(true)

let urlParams = new URLSearchParams(window.location.search)

if (urlParams.get('liId')) {
  linkedinUrl.value = `https://www.linkedin.com/in/${urlParams.get('liId')}`
}

const countrySelected = (countryVal: string) => {
  country.value = countryVal
  emit('countrySelected', countryVal)
}

const stateSelected = (stateVal: string) => {
  state.value = stateVal
  emit('stateSelected', stateVal)
}

const citySelected = (cityVal: string) => {
  city.value = cityVal
  emit('citySelected', cityVal)
}

const search = () => {
  const regex = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/

  isLiUrlValid.value = regex.test(linkedinUrl.value)

  if (!isLiUrlValid.value) return
  emit('getArchetypeProfileData', linkedinUrl.value)
}
</script>
