<template>
  <div>
    <div class="mb-4">
      <h1 class="mt-0 mb-4 text-3xl blinker-semibold leading-9 text-slate-800">
        New Lookalikes Search
      </h1>
      <p class="leading-6 text-gray-500 inter-medium">
        Lookalikes starts by identifying a person that should be used as the Archetype.<br />
        Our AI will deliver the best candidates matching your Archetype out of a pool of 1B+ people.
      </p>
    </div>
    <BaseCard>
      <template #main>
        <LeftArrowIcon
          v-if="
            currentSearchStep === 'analyzeArchetype' &&
            !loadingArchetypeProfile &&
            !loadingLookAlikeSearch
          "
          class="cursor-pointer absolute left-[34px] top-[207px]"
          @click="currentSearchStep = 'startSearch'"
        />
        <div
          v-if="loadingArchetypeProfile || loadingLookAlikeSearch"
          class="flex justify-center flex-1"
        >
          <div class="p-4">
            <p v-if="loadingArchetypeProfile">Analyzing Archetype Profile...</p>
            <div v-if="loadingLookAlikeSearch">
              <p>Accessing our 1B+ candidates pool...</p>
              <p v-if="displaySecondLoadingSearchMessage">
                Surfacing the best matches for your archetype...
              </p>
            </div>
            <CoolLoader class="m-auto mt-8" />
          </div>
        </div>

        <component
          v-else
          :is="searchSteps[currentSearchStep]"
          :suggested-position-titles="lookAlikesData?.suggestedPositionTitles"
          :loading="loadingRevisedLookAlikeSearch"
          :countries="countries"
          :states="states"
          :cities="cities"
          :states-options="statesOptions"
          :cities-options="citiesOptions"
          :no-search-results="lookAlikesData && lookAlikesData.candidateData.length === 0"
          v-model:archetypeProfileData="archetypeProfileData"
          @get-archetype-profile-data="getArchetypeProfileData"
          @search-lookalike="searchLookalike"
          @country-selected="(selectedCountry) => (countries = selectedCountry)"
          @state-selected="(selectedState) => (states = selectedState)"
          @city-selected="(selectedCity) => (cities = selectedCity)"
          @states-options="(statesOptionsVal) => (statesOptions = statesOptionsVal)"
          @cities-options="(citiesOptionsVal) => (citiesOptions = citiesOptionsVal)"
          @toggled-exp-item="(expItems) => (deletedExpItems = expItems)"
        ></component>
      </template>
    </BaseCard>

    <PastLookalikeSearches
      :show-button="true"
      @resume-search-results-error="searchFailed = true"
      @resume-search-results="resumeSearch"
    />

    <Toaster
      v-if="searchFailed"
      message="Oops something went wrong. please contact our support team"
      color="red"
      :time="5000"
      @hide-toaster="searchFailed = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, toRaw, ref } from 'vue'
import { useRouter } from 'vue-router'

import PastLookalikeSearches from './LookalikeSearch/PastLookalikeSearches.vue'
import Toaster from '@/components/Toaster.vue'
import EditArchetypeProfile from './LookalikeSearch/EditArchetypeProfile.vue'
import LookalikeSearch from './LookalikeSearch/LookalikeSearch.vue'
import BaseCard from '@/components/Cards/BaseCards/BaseCard.vue'
import CoolLoader from '@/components/CoolLoader.vue'
import LeftArrowIcon from '@/components/icons/LeftArrowIcon.vue'

import { useLookalikesStore } from '@/stores/lookalikes'
import type { archetypeProfile } from './interfaces.ts'

import axios from 'axios'
import { captureException } from '@sentry/vue'
import { setLocationSearches } from '@/utils/recent-searches'
import { update } from '@intercom/messenger-js-sdk'

onMounted(() => {
  update({ hide_default_launcher: false })
})

const store = useLookalikesStore()
const router = useRouter()

const searchSteps = {
  startSearch: LookalikeSearch,
  analyzeArchetype: EditArchetypeProfile
}

const currentSearchStep = ref('startSearch')
const loadingArchetypeProfile = ref(false)
const loadingLookAlikeSearch = ref(false)
const lookAlikesData = ref<null | []>(null)
const archetypeProfileData = ref<null | archetypeProfile>(null)
const archetypeProfileDataClean = ref<null | archetypeProfile>(null)
const countries = ref([])
const states = ref([])
const cities = ref([])
const statesOptions = ref([])
const citiesOptions = ref([])
const searchFailed = ref(false)
const linkedinProfileUrl = ref('')
const displaySecondLoadingSearchMessage = ref(false)
const deletedExpItems = ref<string[]>([])

const deleteExpItemFromArchetypeExp = () => {
  archetypeProfileData.value.archetype_experience =
    archetypeProfileData.value.archetype_experience.filter(
      (expItem, idx) => !deletedExpItems.value.includes(`${expItem.company}.${idx}`)
    )
}

const getArchetypeProfileData = async (linkedinProfileUrlArg: string): Promise<void> => {
  setLocationSearches(countries.value, states.value, cities.value)

  loadingArchetypeProfile.value = true
  currentSearchStep.value = 'analyzeArchetype'
  lookAlikesData.value = null

  linkedinProfileUrl.value = linkedinProfileUrlArg

  try {
    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/getArchetypeProfile?linkedinProfileUrl=${linkedinProfileUrl.value}`
    )
    archetypeProfileData.value = res.data
    archetypeProfileDataClean.value = structuredClone(res.data)

    loadingArchetypeProfile.value = false
    store.resetRemovedExpItems()
  } catch (err) {
    currentSearchStep.value = 'startSearch'
    searchFailed.value = true
    captureException(err)
  } finally {
    loadingArchetypeProfile.value = false
  }
}

const searchLookalike = async (): Promise<void> => {
  loadingLookAlikeSearch.value = true

  setTimeout(() => {
    displaySecondLoadingSearchMessage.value = true
  }, 4000)

  store.setArchetypeProfileForSearch(archetypeProfileDataClean.value)
  store.setArchetypeLiProfileUrl(linkedinProfileUrl.value)

  deleteExpItemFromArchetypeExp()

  try {
    const res = await axios.post(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/look-alikes/search/${archetypeProfileData.value.archetypeCacheId}`,
      {
        linkedinProfileUrl: linkedinProfileUrl.value,
        countries: countries.value,
        states: states.value,
        cities: cities.value,
        archetypeProfileData: archetypeProfileData.value,
        deletedExpItems: deletedExpItems.value,
        editedSearch: false
      }
    )
    lookAlikesData.value = res.data

    if (res.data.candidateData.length > 0) {
      store.setLookalikeCurrentHistorySearchId(Number(res.data.searchId))

      store.setSearchResults(res.data)

      store.setCountries(countries.value)
      store.setStates(states.value)
      store.setCities(cities.value)

      router.push({ name: 'searchResults', params: { searchId: res.data.searchId } })
    }
  } catch (err) {
    console.log(err)
    currentSearchStep.value = 'startSearch'
    searchFailed.value = true
    captureException(err)
  } finally {
    loadingLookAlikeSearch.value = false
    displaySecondLoadingSearchMessage.value = false
  }
}

const resumeSearch = (searchId: number) => {
  router.push({ name: 'searchResults', params: { searchId: searchId } })
}
</script>
