<template>
  <BaseTab class="rounded-b-lg bg-white px-5 py-2 drop-shadow-md">
    <AllLookalikeTable
      v-if="store.searchResults"
      :candidate-data="store.searchResults.candidateData"
      :archetype-data="store.searchResults.archetypeData"
      :is-loading="data.loading"
      @shortlist-failed="shortlistFailed"
      @shortlist-succeed="shortlistSucceed"
    />
  </BaseTab>
  <Toaster
    v-if="shortlistSuccess"
    message="Candidate Shortlisted"
    color="green"
    :time="3000"
    @hide-toaster="shortlistSuccess = false"
  ></Toaster>
  <Toaster
    v-if="shortlistError"
    :message="shortlistErrorMessage"
    color="red"
    :time="3000"
    @hide-toaster="shortlistError = false"
  ></Toaster>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue'

import AllLookalikeTable from './AllLookalikeTable.vue'
import Toaster from '@/components/Toaster.vue'
import { useLookalikesStore } from '@/stores/lookalikes'

interface IProps {
  data: Record<string, any>
}

defineProps<IProps>()
const store = useLookalikesStore()

const shortlistErrorMessage = ref('')
const shortlistError = ref(false)
const shortlistSuccess = ref(false)

const shortlistSucceed = (): void => {
  shortlistSuccess.value = true
}

const shortlistFailed = (errorMsg: string) => {
  shortlistError.value = true

  if (errorMsg === 'The candidate already shortlisted') {
    shortlistErrorMessage.value = errorMsg
  } else {
    shortlistErrorMessage.value = 'Opps, something went wrong. please try agian.'
  }
}
</script>
