import { defineStore } from 'pinia'
import axios from 'axios'
import { captureException } from '@sentry/vue'

interface IState {
  shortlist: string[]
  lookalikeCurrentHistorySearchId: number | null
  searchResults: Record<string, any>[] | null
  archetypeProfileForSearch: Record<string, any>[] | null
  removedExpItems: string[]
  countries: string[]
  states: string[]
  cities: string[]
  archetypeLiProfileUrl: string
}

export const useLookalikesStore = defineStore('lookalikes', {
  state: (): IState => ({
    lookalikeCurrentHistorySearchId: null,
    shortlist: [],
    searchResults: null,
    removedExpItems: [],
    countries: [],
    states: [],
    cities: [],
    archetypeProfileForSearch: null,
    archetypeLiProfileUrl: ''
  }),

  actions: {
    async fetchSearchResults(searchId: number) {
      try {
        const res = await axios.get(
          `${import.meta.env.VITE_LOOKALIKES_API}/api/look-alikes/resume-search/${searchId}`
        )
        this.setSearchResults(res.data)
        this.setArchetypeProfileForSearch(res.data.archetype_profile_data_for_search)
        this.setRemovedExpItems(res.data.archetypeData.deletedExpItems)
        this.setArchetypeLiProfileUrl(
          `https://www.linkedin.com/in/${res.data.archetypeData.profileId}`
        )
        this.setCountries(res.data.filters.countries)
        this.setStates(res.data.filters.states)
        this.setCities(res.data.filters.cities)
      } catch (err) {
        captureException(err)
        throw err
      }
    },

    populateShortlist() {
      if (this.searchResults) {
        this.resetShortlist()
        this.searchResults.candidateData.forEach((candidate) => {
          if (candidate.shortlisted) {
            this.addCandidateLiIdToShortlist(candidate.candidateProfileId)
          }
        })
      }
    },
    setSearchResults(data: Record<string, any>[]) {
      this.searchResults = data

      this.populateShortlist()
    },

    setArchetypeProfileForSearch(archetypeProfile: Record<string, any>[]) {
      this.archetypeProfileForSearch = archetypeProfile
    },
    setLookalikeCurrentHistorySearchId(searchId: number) {
      this.lookalikeCurrentHistorySearchId = searchId
    },
    setRemovedExpItems(removedExpItems: string[]) {
      this.removedExpItems = removedExpItems
    },
    setArchetypeLiProfileUrl(liProfileUrl: string) {
      this.archetypeLiProfileUrl = liProfileUrl
    },
    setCountries(countries: string[]) {
      this.countries = countries
    },
    setStates(states: string[]) {
      this.states = states
    },
    setCities(cities: string[]) {
      this.cities = cities
    },
    addCandidateLiIdToShortlist(candidateLiProfileId: string) {
      this.shortlist.push(candidateLiProfileId)
    },
    resetShortlist() {
      this.shortlist = []
    },
    resetRemovedExpItems() {
      this.removedExpItems = []
    },
    removeCandidateLiIdFromShortlist(candidateLiProfileId: string) {
      this.shortlist = this.shortlist.filter((shortlistedCandidateLiProfileId) => {
        return shortlistedCandidateLiProfileId !== candidateLiProfileId
      })
    }
  }
})
