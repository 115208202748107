<template>
  <div
    v-if="showList && multiSelect"
    class="fixed top-[-200px] left-0 h-full w-[96vw]"
    @click="showList = false"
  ></div>
  <BaseInput v-bind="$attrs" :title="title" :label-id="title" :label-size="labelSize">
    <template #default>
      <div :class="{ 'w-full': fullWidth }">
        <div
          v-if="showList"
          class="fixed top-[-200%] left-[-200%] z-0 w-[1000%]"
          @click="showList = false"
        ></div>
        <div class="relative">
          <input
            ref="input"
            class="block h-8 w-full rounded border border-gray-300 bg-white px-2 inter-medium text-gray-500 text-sm"
            :class="{ 'bg-gray-200/50': disabled }"
            type="text"
            :placeholder="placeholderText"
            :value="selectedValue"
            :disabled="disabled"
            autocomplete="hack-to-turn-of-autocomplete-in-chrome"
            data-hj-allow
            @input="debounce"
            @focus="onFocus"
            @focusout="handleFocusOut"
          />
          <CloseButton class="absolute top-[13px] right-2 h-2 w-2" @close="clear"></CloseButton>
        </div>
        <div
          v-if="showList || loadingResults"
          class="absolute z-10 max-h-[165px] overflow-y-scroll overflow-x-hidden rounded-lg border border-gray-200l"
          :class="{ 'w-60': fullWidth === false, 'w-[94%]': fullWidth }"
        >
          <ul class="bg-white shadow" :class="{ 'w-60': fullWidth === false, 'w-full': fullWidth }" tabindex="0" role="input-option">
            <li v-if="loadingResults" class="flex justify-center">
              <Loader class="mt-4 mb-4 w-10" />
            </li>
            <li
              v-if="!loadingResults && searchedData.length === 0"
              class="border-t inter-medium text-gray-500 text-sm border-gray-100 py-1.5 px-3 hover:cursor-pointer hover:bg-gray-100"
            >
              No Results Found
            </li>

            <div
              v-if="showRecentlySearched && Object.entries(recentlySearchedValues).length > 0"
              class="font-bold"
            >
              <li
                v-for="(item, key) in recentlySearchedValues"
                :key="key"
                class="flex justify-between border-t border-gray-100 inter-medium text-gray-500 text-sm py-1.5 pl-3 pr-[21px] px-3 hover:cursor-pointer hover:bg-gray-100"
                data-id="multi-select-option"
                @click="selectValue(item)"
              >
                <div v-if="multiSelect" class="flex gap-4" data-id="multi-select-option">
                  <span class="order-2 font-bold">{{ item }}</span>

                  <input
                    class="order-1 cursor-pointer"
                    type="checkbox"
                    :id="item"
                    data-id="multi-select-option"
                    :checked="multiSelectValue[item] !== undefined"
                  />
                </div>
                <span v-else class="font-bold"> {{ item }}</span>
                <RecentlyViewedIcon class="self-center" />
              </li>
            </div>

            <li
              v-for="(str, idx) in searchedData"
              :key="idx"
              class="border-t border-gray-100 inter-medium text-gray-500 text-sm py-1.5 px-3 hover:cursor-pointer hover:bg-gray-100"
              data-id="multi-select-option"
              @click="selectValue(str)"
            >
              <div v-if="multiSelect" class="flex gap-4" data-id="multi-select-option">
                <span class="order-2"> {{ str }}</span>
                <input
                  class="order-1 cursor-pointer"
                  type="checkbox"
                  :id="str"
                  data-id="multi-select-option"
                  :checked="multiSelectValue[str] !== undefined"
                />
              </div>
              <span v-else> {{ str }}</span>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </BaseInput>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps, ref, watch } from 'vue'
import { captureException } from '@sentry/vue'

import BaseInput from './BaseInput.vue'
import Loader from '../Loader.vue'
import CloseButton from '../CloseButton.vue'
import RecentlyViewedIcon from '@/components/icons/RecentlyViewedIcon.vue'

import { encode } from '../../utils'
import axios from 'axios'

import type { LabelSize } from './interfaces'

interface IProps {
  title?: string
  apiUrl?: string
  staticOptions?: string[] | null
  placeholderText?: string
  autoCompleteValue?: string
  labelSize?: LabelSize
  disabled?: boolean
  filterBy?: Record<string, string>
  fullWidth?: boolean
  queryParams?: string
  showStaticOptions?: boolean
  showStaticOptionsOnFocus?: boolean
  showRecentlySearched?: boolean
  recentlySearchedValues?: string[]
  multiSelect?: boolean
  hideOptions?: boolean
  multiSelectedValues?: Record<string, string>
}

const props = defineProps<IProps>()
const emit = defineEmits(['option-selected', 'focus'])
const input = ref(null)

const selectedValue = ref(
  props.multiSelect && props.multiSelectedValues
    ? Object.keys(props.multiSelectedValues).join(',')
    : props.autoCompleteValue
)
const searchedData = ref<string[]>(props.staticOptions ?? [])
const showList = ref(props.showStaticOptions)
const loadingResults = ref(false)
const multiSelectValue = ref<Record<string, string>>(props.multiSelectedValues ?? {})

let timeoutID: ReturnType<typeof setTimeout> | null = null

watch(
  () => props.autoCompleteValue,
  () => (selectedValue.value = props.autoCompleteValue),
  { deep: true }
)

watch(
  () => props.staticOptions,
  () => (searchedData.value = props.staticOptions),
  { deep: true }
)

watch(
  () => props.hideOptions,
  () => {
    showList.value = !props.hideOptions
  }
)

const selectValue = (value: string): void => {
  if (!props.multiSelect) {
    selectedValue.value = value
    showList.value = false
    emit('option-selected', value)
  } else {
    addOrRemoveValueFromMultiSelect(value)
  }
}

const addOrRemoveValueFromMultiSelect = (val) => {
  if (multiSelectValue.value[val] === undefined) {
    multiSelectValue.value[val] = val
  } else {
    delete multiSelectValue.value[val]
  }

  selectedValue.value = Object.keys(multiSelectValue.value).join(',')

  emit('option-selected', Object.keys(multiSelectValue.value))
}

const clear = (): void => {
  selectedValue.value = ''
  multiSelectValue.value = {}
  searchedData.value =
    props.showStaticOptionsOnFocus && props.staticOptions ? props.staticOptions : []
  showList.value = false

  emit('option-selected', '')
}

defineExpose({ clear });


const getAutocompleteResult = async (search: string): Promise<void> => {
  loadingResults.value = true

  const queryParams = props.filterBy ? `&${props.queryParams}` : `?${props.queryParams}`

  try {
    const res = await axios.get(
      `${props.apiUrl}/${search}${props.filterBy ? `?filterBy=${encode(props.filterBy)}` : ''}${queryParams}`
    )

    if (res.status === 200) {
      const data: string[] = res.data

      searchedData.value = data
      showList.value = true
    } else {
      throw new Error(`${res.status} ${res.statusText}, ${await res.text()}`)
    }
  } catch (err) {
    captureException(err)
    showList.value = false
  } finally {
    loadingResults.value = false
  }
}

const debounce = (event: Event): void => {
  if (timeoutID) {
    clearTimeout(timeoutID)
  }

  const targetValue = (event.target as HTMLInputElement).value
  if (targetValue === '') {
    searchedData.value = []
    selectedValue.value = ''
    showList.value = false

    if (props.showStaticOptionsOnFocus) {
      searchedData.value = props.staticOptions
      showList.value = true
    }

    emit('option-selected', '')

    return
  }

  selectedValue.value = targetValue

  if (props.apiUrl) {
    timeoutID = setTimeout(() => {
      getAutocompleteResult(targetValue)
    }, 500)
  } else {
    serachValueInStaticOption(targetValue)
  }
}

const serachValueInStaticOption = (selectedValue: string) => {
  loadingResults.value = true

  const similarStrings = props.staticOptions.filter((str) => {
    if (typeof selectedValue !== 'string') {
      throw new Error('All elements in string Array must be strings')
    }
    return str.toLowerCase().includes(selectedValue.toLowerCase())
  })

  searchedData.value = similarStrings
  showList.value = true
  loadingResults.value = false
}

const onFocus = () => {
  showList.value = props.showStaticOptionsOnFocus
  emit('focus')
}

const handleFocusOut = (event) => {
  if (!event.relatedTarget || event.relatedTarget.role !== 'input-option') {
    showList.value = false
  } else {
    input.value.focus()
  }
}
</script>
