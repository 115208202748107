<template>
  <BaseTab
    class="rounded-b-lg bg-white px-5 py-2 drop-shadow-md"
    :class="{ 'mx-auto': !showArchetypeData, 'w-[100%]': !showArchetypeData }"
  >
    <div class="">
      <ToggleButton
        label="Show Archetype Info"
        class="mb-2"
        @toggle="showArchetypeData = !showArchetypeData"
        :toggle-state="true"
      />
    </div>
    <div class="flex justify-between">
      <div
        class="w-[48%]"
        :class="{
          'translate-x-[55%] transition duration-700': !showArchetypeData,
          'translate-x-[0%] transition duration-700': showArchetypeData
        }"
      >
        <Profile
          v-if="overviewData"
          :loading="loading"
          :title="candidateProfileTitle"
          :about="overviewData.candidateProfileData.about"
          :education="overviewData.candidateProfileData.education"
          :work-experience="overviewData.candidateProfileData.workExperience"
        >
          <template #headerSection>
            <RecentlyViewedCandidate :candidate-profile-id="props.data.candidateProfileId" :display-at-first-time="false" class="ml-3 self-center mr-2 text-gray-500"></RecentlyViewedCandidate>

            <div class="flex mr-0 mx-auto">
              <CandidateShortlistButton
                :is-shortlisted="shortlisted"
                :search-id="props.data.searchId"
                :archetype-li-profile-id="props.data.archetypeProfileId"
                :candidate-li-profile-id="props.data.candidateProfileId"
                @shortlistSucceed="() => successHandler()"
                @shortlistFailed="(err) => errorHandler(err)"
                class="hr-ac-ml-auto"
              />

              <BaseLinkButton
                :link="'/?liId=' + props.data.candidateProfileId"
                target="_blank"
                rel="noreferrer"
                text="Use As Archetype"
              />
            </div>
          </template>
        </Profile>
      </div>
      <Transition
        enter-from-class="translate-x-[10%] opacity-0"
        leave-to-class="translate-x-[10%] opacity-0"
        enter-active-class="transition duration-500"
        leave-active-class="transition duration-500"
      >
        <div v-if="showArchetypeData" class="w-[48%]">
          <Profile
            v-if="overviewData"
            :title="archetyProfileTitle"
            :about="overviewData.archetypeProfileData.about"
            :education="overviewData.archetypeProfileData.education"
            :work-experience="overviewData.archetypeProfileData.workExperience"
            :loading="loading"
          />
        </div>
      </Transition>
    </div>
  </BaseTab>
  <Toaster
    v-if="shortlistSuccess"
    message="Candidate Shortlisted"
    color="green"
    :time="3000"
    @hide-toaster="shortlistSuccess = false"
  ></Toaster>
  <Toaster
    v-if="shortlistError"
    :message="shortlistErrorMessage"
    color="red"
    :time="3000"
    @hide-toaster="shortlistError = false"
  ></Toaster>
</template>

<script setup lang="ts">
import { computed, onMounted, defineProps, ref } from 'vue'
import { useRoute } from 'vue-router'

import BaseTab from '@/components/BaseTab.vue'
import Profile from './Profile.vue'
import Toaster from '@/components/Toaster.vue'
import CandidateShortlistButton from '@/components/CandidateShortlistButton.vue'
import BaseLinkButton from '@/components/BaseLinkButton.vue'

import { captureException } from '@sentry/vue'
import axios from 'axios'
import ToggleButton from '@/components/ToggleButton.vue'
import RecentlyViewedCandidate from '@/components/RecentlyViewedCandidate.vue'

interface IProps {
  data: {
    searchId: number
    archetypeProfileId: string
    candidateProfileId: string
    shortlisted: boolean
  }
}

type profileData = {
  name: string
  about: string
  workExperience: { canonical_title: string; company: string; starts_at: string; ends_at: string }[]
  education: { degree: string; school: string; field_of_study: string }[]
}

interface OverviewDataResponse {
  candidateProfileData: profileData
  archetypeProfileData: profileData
}

const props = defineProps<IProps>()
const route = useRoute()
const overviewData = ref<OverviewDataResponse | null>(null)
const loading = ref(false)
const shortlistSuccess = ref()
const shortlistError = ref()
const shortlistErrorMessage = ref()
const showArchetypeData = ref(true)

onMounted(async () => {
  loading.value = true

  try {
    const res = await axios.get<OverviewDataResponse>(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/look-alike/profile/search/${route.params.searchId}/candidate/${route.params.candidateId}/overview`
    )

    overviewData.value = res.data
  } catch (error) {
    captureException(error)
  } finally {
    loading.value = false
  }
})

const candidateProfileTitle = computed(() =>
  overviewData.value && overviewData.value && overviewData.value.candidateProfileData.name
    ? `Candidate: ${overviewData.value.candidateProfileData.name}`
    : ''
)
const archetyProfileTitle = computed(() =>
  overviewData.value && overviewData.value && overviewData.value.archetypeProfileData.name
    ? `Archetype: ${overviewData.value.archetypeProfileData.name}`
    : ''
)

const shortlisted = ref(props.data.shortlisted)

const errorHandler = (err) => {
  shortlistError.value = false
  shortlistErrorMessage.value = 'Oops something went wrong. please contact our support team'
  shortlisted.value = false
}

const successHandler = () => {
  shortlistSuccess.value = true
  shortlisted.value = true
}
</script>
