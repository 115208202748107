<template>
  <BaseInput :title="title" label-id="select" :label-size="labelSize">
    <template #default>
      <select
        id="select"
        class="mb-1 h-8 text-sm inter-medium rounded border border-gray-300 bg-white px-2 text-gray-500"
        :name="name"
        :form="form"
        :required="required"
        :disabled="disabled"
        @change="optionSelected"
      >
        <option v-if="!loadingOptions" value="" class="text-gray-500">—</option>
        <option v-else class="text-gray-500">loading...</option>
        <template v-if="keyValueOptions">
          <option
            class="text-gray-500"
            v-for="(value, displayValue) in options"
            :key="value"
            :selected="value === option"
            :value="value"
          >
            {{ displayValue }}
          </option>
        </template>
        <template v-else>
          <option
            class="text-gray-500"
            v-for="filterOption in options"
            :key="filterOption"
            :selected="filterOption === option"
            :value="filterOption"
          >
            {{ filterOption }}
          </option>
        </template>
      </select>
    </template>
  </BaseInput>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps, defineExpose, onMounted, ref, watch } from 'vue'
import { captureException } from '@sentry/vue'
import axios from 'axios'

import BaseInput from './BaseInput.vue'
import type { LabelSize } from './interfaces'

interface IProps {
  title?: string
  apiUrl?: string
  colName?: string
  option?: number | string
  keyValueOptions?: boolean
  labelSize?: LabelSize
  staticOptions?: number[] | Record<number | string, boolean | number | string> | string[]
  name?: string
  required?: boolean
  getKeyValueOption?: boolean
  form?: string
  disabled?: boolean
}

const emit = defineEmits(['optionSelected'])
const props = defineProps<IProps>()

const options = ref<number[] | Record<number | string, boolean | number | string> | string[]>(
  props.staticOptions ?? []
)
const loadingOptions = ref(false)

const getFilterOptions = async (): Promise<void> => {
  loadingOptions.value = true

  try {
    const res = await axios.get(props.apiUrl)

    if (res.status === 200) {
      options.value = await res.data
    } else {
      throw new Error(`${res.status} ${res.statusText}`)
    }
  } catch (err) {
    console.error(err)
    captureException(err)
  } finally {
    loadingOptions.value = false
  }
}

const optionSelected = (event: Event): void => {
  if (props.getKeyValueOption) {
    emit(
      'optionSelected',
      Object.keys(options.value).find(
        (key) =>
          event.target &&
          options.value[key as keyof typeof options.value] ===
            (isNaN(parseInt((event.target as HTMLSelectElement).value, 10))
              ? (event.target as HTMLSelectElement).value
              : parseInt((event.target as HTMLSelectElement).value, 10))
      ),
      (event.target as HTMLSelectElement).value
    )
  } else {
    emit('optionSelected', {
      colName: props.colName,
      value: (event.target as HTMLSelectElement).value
    })
  }
}

onMounted(async () => {
  if (!props.staticOptions && props.apiUrl) {
    await getFilterOptions()
  }
})

watch(
  () => props.apiUrl,
  async () => {
    await getFilterOptions()
  },
  { deep: true }
)

watch(
  () => props.staticOptions,
  () => {
    options.value = props.staticOptions ?? []
  },
  { deep: true }
)

defineExpose({
  getFilterOptions
})
</script>
