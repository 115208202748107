<template>
  <div class="p-4 m-auto relative">
    <BaseCardWithHeader class="mb-4" title="Selected Location" custom-css-for-header="!p-2">
      <template #main>
        <div class="p-4">
          <LocationFilter
            :show-explanation="false"
            :selected-countries="countries"
            :selected-states="states"
            :selected-cities="cities"
            :states-options="statesOptions"
            :cities-options="citiesOptions"
            @country-selected="(selectedCountries) => $emit('countrySelected', selectedCountries)"
            @state-selected="(selectedStates) => $emit('stateSelected', selectedStates)"
            @city-selected="(selectedCities) => $emit('citySelected', selectedCities)"
          />
        </div>
      </template>
    </BaseCardWithHeader>
    <BaseCardWithHeader
      class="m-auto"
      :title="`Edit Archetype: ${archetypeProfileData.name}`"
      custom-css-for-header="!p-2"
    >
      <template #main>
        <div class="flex flex-col p-4 grow-0">
          <div>
            <div class="text-gray-500 inter-medium text-sm">
              <p>
                Editing the archetype is optional and allows for more search flexibility. Tune
                current archetype values to receive the perfect candidates for you.
              </p>
              <!-- <p>The selected location for this search is: {{ selectedLocation }}</p> -->
            </div>
            <div v-if="noSearchResults" class="mt-2">
              <p class="text-rose-600">
                No results found for given archetype with a current position of:
                {{ archetypeCurrentPositionTitle ?? 'N\\A' }}
              </p>
              <p class="text-gray-500 inter-medium font-medium text-sm">
                To proceed with this search, please select the closest position title from the list
                below, or you can type any position title using a free text search:
              </p>
            </div>
          </div>

          <div class="flex gap-4 mt-5 grow-0">
            <BaseCardWithHeader
              title="Current Experience"
              class="flex-1"
              custom-css-for-header="!p-2"
            >
              <template #main>
                <div class="flex flex-col p-2 flex-1">
                  <div class="gap-2">
                    <div class="text-gray-500 inter-medium text-sm flex">
                      <div>
                        {{ archetypeCurrentPositionTitle }} •
                        {{
                          convertMonthsToYearsMonths(
                            archetypeProfileData.archetype_experience_in_position
                          )
                        }}

                        <span class="block mt-1">
                          <div>
                            {{ archetypeProfileData.company }} •
                            {{ archetypeProfileData.industry }}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>

                  <SuggestedTitlesAutoComplete
                    class="mt-4"
                    title="role title"
                    label-size="small"
                    :auto-complete-value="archetypeProfileData.title"
                    placeholder-text="Start typing a position title..."
                    :api-url="apiUrl"
                    :query-params="locationQueryParams"
                    :static-options="suggestedPositionTitles"
                    :show-static-options="showPositionTitlesOptions"
                    @option-selected="
                      (val) => (
                        (archetypeProfileData.title = val),
                        (archetypeProfileData.canonicalTitle = val),
                        (archetypeProfileData.revizedTitle = true)
                      )
                    "
                  />
                  <Input
                    class="mt-2"
                    title="MIN YEARS OF TOTAL ROLE EXPERIENCE"
                    label-size="small"
                    type="number"
                    label="small"
                    :min="0"
                    :max="100"
                    :value="
                      Math.round(archetypeProfileData.archetype_total_experience_in_position / 12)
                    "
                    @input-change="
                      (value) => (
                        (archetypeProfileData.archetype_total_experience_in_position = value * 12),
                        (archetypeProfileData.revizedTotalExpInPosition = value * 12)
                      )
                    "
                  />
                  <Input
                    class="mt-2"
                    title="MIN YEARS OF CAREER EXPERIENCE"
                    type="number"
                    label-size="small"
                    :min="0"
                    :max="100"
                    :value="Math.round(archetypeProfileData.archetype_total_career_experience / 12)"
                    @input-change="
                      (value) => (
                        (archetypeProfileData.archetype_total_career_experience = value * 12),
                        (archetypeProfileData.revizedTotalCareerPosition = value * 12)
                      )
                    "
                  />
                  <BaseButton
                    class="mt-5"
                    :text="textForSearchButton ?? 'Let`s Go'"
                    text-align-center
                    @click="$emit('searchLookalike')"
                  />
                </div>
              </template>
            </BaseCardWithHeader>

            <BaseCardWithHeader title="Past Experience" custom-css-for-header="!p-2">
              <template #main>
                <div class="p-4 text-gray-500 inter-medium text-sm overflow-y-auto max-h-[290px]">
                  <p v-if="archetypeProfileData.archetype_experience.length === 0">
                    Work Experience data is not available
                  </p>

                  <ul v-if="archetypeProfileData.archetype_experience" class="list-disc">
                    <template v-for="(experience, companyName) in workExp">
                      <div class="mb-4">
                        <div class="flex">
                          <div class="w-10 h-10 self-center mr-4 justify-center items-center flex">
                            <CompanyLogo
                              :src="experience.positions[0].logo"
                              :company-name="companyName"
                              :link="
                                experience.positions[0].url ?? experience.positions[0].linkedin_url
                              "
                            />
                          </div>
                          <div>
                            <u
                              >{{ companyName }} ({{
                                experience.positions[0]
                                  ? convertMonthsToYearsMonths(
                                      experience.positions[0]['total_time_in_company']
                                    )
                                  : ''
                              }})</u
                            >

                            <li
                              v-for="(position, idx) in experience.positions"
                              :key="idx"
                              class="relative leading-9 flex"
                              :class="{
                                'cursor-pointer':
                                  `${companyName}.${idx}` !== `${Object.keys(workExp)[0]}.0`
                              }"
                              @click="toggleRemoveExpItem(`${companyName}.${idx}`)"
                            >
                              <div
                                v-if="`${companyName}.${idx}` !== `${Object.keys(workExp)[0]}.0`"
                                class="mr-2 self-center"
                              >
                                <UndoIcon
                                  v-if="deletedExpItems.includes(`${companyName}.${idx}`)"
                                />
                                <MinusIcon v-else />
                              </div>
                              <div
                                :class="{
                                  'line-through': deletedExpItems.includes(`${companyName}.${idx}`),
                                  'ml-[26px]':
                                    `${companyName}.${idx}` === `${Object.keys(workExp)[0]}.0`
                                }"
                              >
                                {{ position.title }} ({{ formatDate(position.starts_at) }} -
                                {{ formatDate(position.ends_at) ?? 'Current' }})
                              </div>
                            </li>
                          </div>
                        </div>
                      </div>
                    </template>
                  </ul>
                </div>
              </template>
            </BaseCardWithHeader>
          </div>
        </div>
      </template>
    </BaseCardWithHeader>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, defineModel, ref } from 'vue'

import { convertMonthsToYearsMonths } from '../../utils'

import Input from '@/components/inputs/Input.vue'
import SuggestedTitlesAutoComplete from './SuggestedTitlesAutoComplete.vue'
import BaseCardWithHeader from '@/components/Cards/BaseCards/BaseCardWithHeader.vue'
import BaseButton from '@/components/BaseButton.vue'
import LocationFilter from './LocationFilter.vue'
import CompanyLogo from '@/components/CompanyLogo.vue'
import MinusIcon from '@/components/icons/MinusIcon.vue'
import UndoIcon from '@/components/icons/UndoIcon.vue'
import { useLookalikesStore } from '@/stores/lookalikes'

interface IProps {
  countries: string[]
  states: string[]
  cities: string[]
  statesOptions: string[]
  citiesOptions: string[]
  noSearchResults: boolean
  suggestedPositionTitles: string[] | null
  textForSearchButton?: string
}

const emit = defineEmits([
  'searchLookalike',
  'back',
  'countrySelected',
  'stateSelected',
  'citySelected',
  'toggledExpItem'
])

const props = defineProps<IProps>()
const store = useLookalikesStore()

const archetypeProfileData = defineModel<Record<string, any>[]>('archetypeProfileData') //archetypeProfileData

const apiUrl = `${import.meta.env.VITE_LOOKALIKES_API}/api/look-alikes/search/auto-complete`

const archetypeCurrentPositionTitle = archetypeProfileData.value.title

const deletedExpItems = ref<string[]>(store.removedExpItems)

const showPositionTitlesOptions = computed(() => props.suggestedPositionTitles)

const locationQueryParams = computed(
  () =>
    `countries=${JSON.stringify(props.countries)}&states=${JSON.stringify(props.states)}&cities=${JSON.stringify(props.cities)}`
)

const workExp = computed(() => {
  if (archetypeProfileData.value.archetype_experience) {
    return archetypeProfileData.value.archetype_experience.reduce((acc, cur) => {
      if (acc[cur.company] !== undefined) {
        acc[cur.company]['positions'].push(cur)
      } else {
        acc[cur.company] = { positions: [cur] }
      }
      return acc
    }, {})
  }

  return {}
})

const formatDate = (date) => {
  if (date === null) {
    return null
  }
  const dateObj = new Date(date)

  return `${dateObj.toLocaleString('default', { month: 'short' })} ${dateObj.getFullYear()}`
}

const toggleRemoveExpItem = (companyNameAndIndex: string) => {
  if (companyNameAndIndex === `${Object.keys(workExp.value)[0]}.0`) {
    return
  }

  if (!deletedExpItems.value.includes(companyNameAndIndex)) {
    deletedExpItems.value.push(companyNameAndIndex)
  } else {
    deletedExpItems.value = deletedExpItems.value.filter((id) => id !== companyNameAndIndex)
  }

  store.setRemovedExpItems(deletedExpItems.value)

  emit('toggledExpItem', deletedExpItems.value)
}
</script>
