import { createRouter, createWebHistory } from 'vue-router'

import axios from 'axios'

import LoginPage from '@/views/Auth/LoginPage.vue'
import Lookalikes from '@/views/Lookalikes.vue'
import LookAlikeResults from '@/views/LookAlikeResult/LookAlikeResults.vue'
import CandidatePage from '@/views/CandidatePage/CandidatePage.vue'
import NotFound from '@/views/Errors/NotFound.vue'
import RegisterPage from '@/views/Auth/RegisterPage.vue'
import ForgotPassword from '@/views/Auth/ForgotPasswordPage.vue'
import ResetPasswordPage from '@/views/Auth/ResetPasswordPage.vue'
import Profile from '@/views/User/Profile.vue'

import { useUserStore } from '@/stores/user'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/register',
      name: 'register',
      component: RegisterPage,
      // meta: { layout: 'auth' },
      beforeEnter: async (to, from) => {
        try {
          const res = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/user`)

          useUserStore().login(res.data)

          return { name: 'lookalikes' }
        } catch (error) {}
        return true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      meta: { layout: 'auth' },
      beforeEnter: async (to, from) => {
        try {
          const res = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/user`)

          useUserStore().login(res.data)

          return to.query.redirect ?? { name: 'lookalikes' }
        } catch (error) {
          return true
        }
      }
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: ForgotPassword,
      meta: { layout: 'auth' },
      beforeEnter: async (to, from) => {
        try {
          const res = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/user`)

          useUserStore().login(res.data)

          return { name: 'lookalikes' }
        } catch (error) {}
        return true
      }
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: ResetPasswordPage,
      meta: { layout: 'auth' },
      beforeEnter: async (to, from) => {
        try {
          const res = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/user`)

          useUserStore().login(res.data)

          return { name: 'lookalikes' }
        } catch (error) {}
        return true
      }
    },
    {
      path: '/',
      name: 'lookalikes',
      component: Lookalikes,
      meta: { layout: 'default' }
    },
    {
      path: '/search-results/:searchId',
      name: 'searchResults',
      component: LookAlikeResults,
      meta: { layout: 'default' }
    },
    {
      path: '/search-results/:searchId/candidate/:candidateId',
      name: 'candidate',
      component: CandidatePage,
      meta: { layout: 'default' }
    },
    {
      path: '/404',
      name: 'notFound',
      component: NotFound,
      meta: { layout: 'default' }
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { layout: 'default' }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: NotFound,
      meta: { layout: 'default' }
    }
  ]
})

router.beforeEach(async (to, from) => {
  const userStore = useUserStore()

  if (
    // make sure the user is authenticated
    !userStore.isLoggedIn &&
    // ❗️ Avoid an infinite redirect
    to.name !== 'login' &&
    to.name !== 'register' &&
    to.name !== 'forgotPassword' &&
    to.name !== 'resetPassword'
  ) {
    // redirect the user to the login page
    return { name: 'login', query: { redirect: to.fullPath } }
  }

  return true
})

export default router
