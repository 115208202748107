<template>
  <div
    v-if="show"
    :class="`fixed flex bottom-0 right-0 z-[51] rounded-md border border-solid border-current p-2 text-center font-bold ${colorClasses}`"
  >
    {{ message }}

    <CloseButton v-if="isConstant" class="flex ml-2 w-2.5" @close="close" />
  </div>
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps, onMounted, ref } from 'vue'
import CloseButton from '@/components/CloseButton.vue'

interface IProps {
  message: string
  isConstant?: boolean
  time?: number
  color?: 'green' | 'red'
}

const show = ref(true)

const props = defineProps<IProps>()
const emit = defineEmits(['hide-toaster'])

const colorClassesMap = {
  green: 'bg-green-50 text-green-600',
  red: 'bg-red-50 text-red-500'
}

const colorClasses = computed(() => {
  if (props.color && colorClassesMap[props.color]) {
    return colorClassesMap[props.color]
  }

  return colorClassesMap.green
})

const close = () => {
  show.value = false
  emit('hide-toaster')
}

onMounted(() => {
  if (props.time && props.time > -1) {
    setTimeout(() => {
      show.value = false
      emit('hide-toaster')
    }, props.time)
  }
})
</script>
