<template>
  <BaseTab class="rounded-b-lg bg-white px-5 py-2 drop-shadow-md">
    <div class="max-h-[71vh] overflow-x-hidden">
      <ShortlistTable
        :candidate-data="candidatesData"
        :archetype-name="store.searchResults.archetypeData.name"
        @delete-short-list="deleteShortList"
      />
    </div>
  </BaseTab>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import ShortlistTable from './ShortlistTable.vue'
import { useLookalikesStore } from '@/stores/lookalikes'
import axios from 'axios'

const store = useLookalikesStore()
const route = useRoute()
const candidatesData = computed(() =>
  store.searchResults.candidateData.filter((candidate) =>
    store.shortlist.includes(candidate.candidateProfileId)
  )
)
const deleteShortList = async (candidateLiProfileId: string) => {
  await axios.delete(
    `${import.meta.env.VITE_LOOKALIKES_API}/api/look-alike/shortlist/${route.params.searchId}/${candidateLiProfileId}`
  )
  store.removeCandidateLiIdFromShortlist(candidateLiProfileId)
}
</script>
