<template>
  <div class="flex flex-col">
    <div class="flex">
      <AutoCompleteInput
        class="w-72"
        title="country"
        :static-options="
          countries.filter((country) => !recentlySearchedCountries.includes(country))
        "
        :show-static-options-on-focus="true"
        :show-recently-searched="true"
        :recently-searched-values="recentlySearchedCountries"
        :multi-select="true"
        :multi-selected-values="convertArrToObj(localSelectedCountries)"
        :hide-options="hideCountryOptions"
        @option-selected="onCountryChange"
        @focus="handleFocusOnCountry"
        @focusout="handleFocusOutCountry"
      />
      <AutoCompleteInput
        class="w-72 mr-4 ml-4"
        title="State"
        :static-options="states"
        :show-static-options-on-focus="true"
        :show-recently-searched="true"
        :recently-searched-values="recentlySearchedStates"
        :multi-select="true"
        :multi-selected-values="convertArrToObj(localSelectedStates)"
        :hide-options="hideStateOptions"
        @option-selected="onStateChange"
        @focus="handleFocusOnState"
        @focusout="handleFocusOutState"
      />
      <AutoCompleteInput
        class="w-72"
        title="City"
        :static-options="cities"
        :show-static-options-on-focus="true"
        :show-recently-searched="true"
        :recently-searched-values="recentlySearchedCities"
        :multi-select="true"
        :multi-selected-values="convertArrToObj(localSelectedCity)"
        :hide-options="hideCityOptions"
        @option-selected="onCityChange"
        @focus="handleFocusOnCity"
        @focusout="handleFocusOutCity"
      />
    </div>
    <p v-if="showExplanation" class="text-gray-500 text-sm inter-medium mt-2">
      Optional: to narrow your Lookalikes search results to a specific region, please select it
      above. You can filter by just country, or just country and state/province, or to a specific
      city.
      <br />
      <i>Note: If no region filters are applied, results will be global.</i>
    </p>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

import axios from 'axios'
import AutoCompleteInput from '@/components/inputs/AutoCompleteInput.vue'
import { captureException } from '@sentry/vue'
import { convertArrToObj } from '@/utils/type-format-helper'
import { getLocationRecentSearches } from '@/utils/recent-searches'

interface IProps {
  showExplanation?: boolean
  showRecentlySearched?: boolean
  selectedCountries?: string[]
  selectedStates?: string[]
  selectedCities?: string[]
  statesOptions?: string[]
  citiesOptions?: string[]
}

const emit = defineEmits([
  'countrySelected',
  'stateSelected',
  'citySelected',
  'statesOptions',
  'citiesOptions'
])
const props = defineProps<IProps>()

const countries = [
  'Andorra',
  'Australia',
  'Austria',
  'Bangladesh',
  'Belgium',
  'Brazil',
  'Bulgaria',
  'Canada',
  'Chile',
  'Colombia',
  'Croatia',
  'Cyprus',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Georgia',
  'Germany',
  'Hungary',
  'India',
  'Ireland',
  'Israel',
  'Italy',
  'Japan',
  'South Korea',
  'Lithuania',
  'Mexico',
  'Netherlands',
  'New Zealand',
  'Norway',
  'Philippines',
  'Poland',
  'Portugal',
  'Romania',
  'Russia',
  'Serbia',
  'Singapore',
  'South Africa',
  'Spain',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Thailand',
  'Turkey',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States'
]

const localSelectedCountries = ref<string[]>(props.selectedCountries ?? [])
const localSelectedStates = ref<string[]>(props.selectedStates ?? [])
const localSelectedCity = ref(props.selectedCities ?? [])
const states = ref(props.statesOptions ?? [])
const cities = ref(props.citiesOptions ?? [])
const hideCountryOptions = ref(false)
const hideStateOptions = ref(false)
const hideCityOptions = ref(false)

const recentlySearchedCountries = ref(getLocationRecentSearches('countries', 3))
const recentlySearchedStates = ref(getLocationRecentSearches('states', 3, states.value))
const recentlySearchedCities = ref(getLocationRecentSearches('cities', 3, cities.value))

const onCountryChange = async (val: string[] | string): void => {
  if (val === '' || val.length === 0) {
    states.value = []
    cities.value = []

    localSelectedStates.value = []
    localSelectedCity.value = []

    emit('countrySelected', [])
    return
  }

  localSelectedCountries.value = val

  try {
    const res = await axios(`${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/states`, {
      params: {
        countries: JSON.stringify(localSelectedCountries.value)
      }
    })

    recentlySearchedStates.value = getLocationRecentSearches('states', 3, res.data)
    states.value = res.data.filter((item) => {
      return !recentlySearchedStates.value.includes(item)
    })
  } catch (err) {
    captureException(err)
  }

  emit('countrySelected', val)
  emit('statesOptions', states.value)
}

const onStateChange = async (val: string[] | string): Promise<void> => {
  if (val === '' || val.length === 0) {
    localSelectedCity.value = []
    emit('stateSelected', [])
    return
  } else {
    localSelectedStates.value = val

    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/cities`,
      {
        params: {
          countries: JSON.stringify(localSelectedCountries.value),
          states: JSON.stringify(localSelectedStates.value)
        }
      }
    )

    localSelectedCity.value = []
    recentlySearchedCities.value = getLocationRecentSearches('cities', 3, res.data)
    cities.value = res.data.filter((item) => {
      return !recentlySearchedCities.value.includes(item)
    })
  }

  emit('stateSelected', val)
  emit('citiesOptions', cities.value)
}

const onCityChange = (val): void => {
  if (val === '' || val.length === 0) {
    localSelectedCity.value = []
    emit('citySelected', [])
    return
  }
  localSelectedCity.value = val
  emit('citySelected', val)
}

const handleFocusOutCountry = (event) => {
  if (
    event.explicitOriginalTarget &&
    event.explicitOriginalTarget.dataset &&
    event.explicitOriginalTarget.dataset.id !== 'multi-select-option'
  ) {
    hideCountryOptions.value = true
  }
}

const handleFocusOnCountry = () => {
  hideCountryOptions.value = false
  hideStateOptions.value = true
  hideCityOptions.value = true
}

const handleFocusOutState = (event) => {
  if (
    event.explicitOriginalTarget &&
    event.explicitOriginalTarget.dataset &&
    event.explicitOriginalTarget.dataset.id !== 'multi-select-option'
  ) {
    hideStateOptions.value = true
  }
}

const handleFocusOnState = () => {
  hideCountryOptions.value = true
  hideStateOptions.value = false
  hideCityOptions.value = true
}

const handleFocusOutCity = (event) => {
  if (
    event.explicitOriginalTarget &&
    event.explicitOriginalTarget.dataset &&
    event.explicitOriginalTarget.dataset.id !== 'multi-select-option'
  ) {
    hideCityOptions.value = true
  }
}

const handleFocusOnCity = () => {
  hideCountryOptions.value = true
  hideStateOptions.value = true
  hideCityOptions.value = false
}

onMounted(async () => {
  window.addEventListener('changed-recently-searches', () => {
    recentlySearchedCountries.value = getLocationRecentSearches('countries', 3)
    recentlySearchedStates.value = getLocationRecentSearches('states', 3, states.value)
    recentlySearchedCities.value = getLocationRecentSearches('cities', 3, cities.value)
  })

  if (props.selectedCountries) {
    try {
      const res = await axios(
        `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/states`,
        {
          params: {
            countries: JSON.stringify(localSelectedCountries.value)
          }
        }
      )

      states.value = res.data
    } catch (err) {
      captureException(err)
    }
  }

  if (props.selectedStates) {
    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/cities`,
      {
        params: {
          countries: JSON.stringify(localSelectedCountries.value),
          states: JSON.stringify(localSelectedStates.value)
        }
      }
    )

    cities.value = res.data
  }
})
</script>
