<template>
  <BaseModal class="!p-0" @close="closeModal">
    <template #default>
      <div class="flex justify-center w-[1000px] h-[700px]">
        <EditArchetypeProfile
          v-if="!loadingLookAlikeSearch && archetypeProfileData"
          class="flex-1"
          v-model:archetypeProfileData="archetypeProfileData"
          :no-search-results="lookalikesResults && lookalikesResults.candidateData.length === 0"
          text-for-search-button="Edit Search"
          :countries="countries"
          :states="states"
          :cities="cities"
          @country-selected="(selectedCountries) => (countries = selectedCountries)"
          @state-selected="(selectedStates) => (states = selectedStates)"
          @city-selected="(selectedCities) => (cities = selectedCities)"
          @search-lookalike="searchLookalike"
          @toggled-exp-item="(expItems) => (deletedExpItems = expItems)"
        />
        <div v-else class="self-center">
          <p>Accessing our 1B+ candidates pool...</p>
          <p v-if="displaySecondLoadingSearchMessage">
            Surfacing the best matches for your archetype...
          </p>
          <CoolLoader class="m-auto mt-4" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { defineEmits, onMounted, ref, toRaw } from 'vue'
import { useRouter } from 'vue-router'

import BaseModal from '@/components/BaseModal.vue'
import EditArchetypeProfile from '../LookalikeSearch/EditArchetypeProfile.vue'
import CoolLoader from '@/components/CoolLoader.vue'

import { useLookalikesStore } from '@/stores/lookalikes'

import axios from 'axios'
import { captureException } from '@sentry/vue'

const emit = defineEmits(['close', 'isLocationFallback', 'searchEdited'])
const store = useLookalikesStore()
const router = useRouter()

const archetypeProfileData = ref(store.archetypeProfileForSearch)
const loadingLookAlikeSearch = ref(false)
const countries = ref(store.countries)
const states = ref(store.states)
const cities = ref(store.cities)
const lookalikesResults = ref(null)
const searchFailed = ref(false)
const deletedExpItems = ref<string[]>([])
const displaySecondLoadingSearchMessage = ref(false)

onMounted(async () => {
  if (store.archetypeProfileForSearch !== null) return

  try {
    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/getArchetypeProfileForSearchEdit/${store.lookalikeCurrentHistorySearchId}`
    )

    store.setArchetypeProfileForSearch(structuredClone(res.data.profile_data_for_search))
    store.setArchetypeLiProfileUrl(`https://www.linkedin.com/in/${res.data.profile_id}`)
    store.setRemovedExpItems(res.data.deleted_exp_items)

    archetypeProfileData.value = res.data.profile_data_for_search
    countries.value = res.data.countries
    states.value = res.data.states
    cities.value = res.data.cities

    store.setCountries(res.data.countries)
    store.setStates(res.data.states)
    store.setCities(res.data.cities)
  } catch (error) {}
})

const archetypeDataAfterExpItemsDeleted = () => {
  const archetypeData = structuredClone(toRaw(store.archetypeProfileForSearch))

  archetypeData.archetype_experience = archetypeData.archetype_experience.filter(
    (expItem, idx) => !deletedExpItems.value.includes(`${expItem.company}.${idx}`)
  )

  return archetypeData
}

const searchLookalike = async (): Promise<void> => {
  loadingLookAlikeSearch.value = true

  setTimeout(() => {
    displaySecondLoadingSearchMessage.value = true
  }, 4000)

  try {
    const res = await axios.post(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/look-alikes/search/${archetypeProfileData.value.archetypeCacheId}`,
      {
        linkedinProfileUrl: store.archetypeLiProfileUrl,
        countries: countries.value,
        states: states.value,
        cities: cities.value,
        archetypeProfileData: archetypeDataAfterExpItemsDeleted(),
        deletedExpItems: deletedExpItems.value,
        editedSearch: true,
        searchId: store.lookalikeCurrentHistorySearchId
      }
    )

    lookalikesResults.value = res.data

    if (res.data.candidateData.length > 0) {
      store.setLookalikeCurrentHistorySearchId(Number(res.data.searchId))
      store.setSearchResults(res.data)
      store.setCountries(countries.value)
      store.setStates(states.value)
      store.setCities(cities.value)

      router.push({ name: 'searchResults', params: { searchId: res.data.searchId }, replace: true })

      emit('isLocationFallback', res.data.isLocationFallback)
      emit('close')
      emit('searchEdited')
    }
  } catch (err) {
    console.log(err)

    searchFailed.value = true
    captureException(err)
  } finally {
    loadingLookAlikeSearch.value = false
    displaySecondLoadingSearchMessage.value = false
  }
}

const closeModal = () => {
  if (loadingLookAlikeSearch.value) return

  emit('close')
}
</script>
