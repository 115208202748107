<template>
  <div>
    <div
      v-if="props.candidateData && props.candidateData.length > 0"
      class="flex justify-end py-2 lg:mr-[50px]"
    >
      <BaseButton class="text-xxs" bg-color="green" height="h-6" text="Export" :onClick="exportCsv">
        <FileDownloadIcon class="ml-2 w-3" />
      </BaseButton>
    </div>
    <div class="max-h-[61vh] overflow-scroll overflow-x-hidden">
      <BaseTableClientSide
        v-if="props.candidateData && props.candidateData.length > 0"
        :table="table"
        header-cell-padding-class="px-4 py-2"
        :no-data="props.candidateData && props.candidateData.length === 0"
        no-data-message="No Lookalikes shortlisted for this Archetype yet. To shortlist a Lookalike, use the Shortlist button in the All Lookalikes results tab."
        @row-clicked="viewCandidate"
      />
    </div>
    <div
      v-if="props.candidateData && props.candidateData.length === 0"
      class="flex flex-col items-center justify-center py-8 px-6"
    >
      <NovaTableIcon />
      <p class="mt-3 mb-3 max-w-[50%] text-base inter-medium text-gray-500">
        No Lookalikes shortlisted for this Archetype yet. To shortlist a Lookalike, use the
        Shortlist button in the All Lookalikes results tab.
      </p>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="tsx" setup>
import { defineEmits, defineProps, ref, withModifiers } from 'vue'
import { useRouter } from 'vue-router'
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useVueTable
} from '@tanstack/vue-table'

import BaseTableClientSide from '@/components/table/BaseTableClientSide.vue'
import BaseLinkButton from '@/components/BaseLinkButton.vue'
import BaseButton from '@/components/BaseButton.vue'
import ColumnSort from '@/components/table/ColumnSort.vue'
import NovaTableIcon from '@/components/icons/NovaTableIcon.vue'

import { convertMonthsToYearsMonths } from '../../../../utils/date'
import { useLookalikesStore } from '@/stores/lookalikes'
import FileDownloadIcon from '@/components/icons/FileDownloadIcon.vue'
import { download, generateCsv, mkConfig } from 'export-to-csv'
import { locationShortenFormat } from '@/utils/location-formatter'
import CompanyLogo from '@/components/CompanyLogo.vue'
import LinkedinIcon from '@/components/icons/LinkedinIcon.vue'

interface IShortlistedLookalikesData {
  archetypeProfileId: string
  candidateProfileId: string
  candidateId: number
  rank: number
  name: string
  position: string
  employer: string
  education: string
  location: string
  roleExp: number
  careerExp: number
  industry: string
  companyLiUrl: string
  companyLogo: string
  companyName: string
}

interface IShortlistedLookalikesTable {
  candidateData: Record<string, any>[]
  archetypeName: string
  loading?: boolean
}

const emit = defineEmits(['shortlistSucceed', 'shortlistFailed', 'deleteShortList'])

const props = defineProps<IShortlistedLookalikesTable>()
const store = useLookalikesStore()
const router = useRouter()

const columnHelper = createColumnHelper<IShortlistedLookalikesData>()

const columns = [
  columnHelper.accessor('rank', {
    id: 'rank',
    header: 'rank',
    size: 60,
    cell: (prop) => <span class={'block py-4 pr-2 pl-4'}>{prop.getValue()}</span>
  }),
  columnHelper.accessor('name', {
    header: 'name',
    size: 115,
    cell: (prop) => (
      <a
        class={'flex'}
        href={`https://www.linkedin.com/in/${prop.row.original.candidateProfileId}`}
        target="_blank"
        rel="noreferrer"
        onClick={withModifiers(() => {}, ['stop'])}
      >
        <LinkedinIcon class={'h-4 w-4 self-center'} />
        <span class={'block py-4 pl-2 pr-4'}>{prop.getValue()}</span>
      </a>
    )
  }),
  columnHelper.accessor('position', {
    header: 'position',
    size: 180,
    cell: (prop) => <span class={'block p-4'}>{prop.getValue()}</span>
  }),
  columnHelper.accessor('employer', {
    header: 'employer',
    size: 100,
    cell: (prop) => (
      <div class="w-24 px-4 justify-center items-center flex py-3">
        <CompanyLogo
          src={prop.row.original.companyLogo}
          company-name={prop.row.original.companyName}
          link={prop.row.original.companyLiUrl}
        />
      </div>
    )
  }),
  columnHelper.accessor('industry', {
    header: 'industry',
    size: 180,
    cell: (prop) => <span class={'block p-4'}>{prop.getValue()}</span>
  }),
  // columnHelper.accessor('education', {
  //     header: 'education',
  // }),
  columnHelper.accessor('location', {
    header: 'location',
    size: 180,
    cell: (prop) => (
      <span class={'block p-4'}>
        {locationShortenFormat(
          prop.row.original.country,
          prop.row.original.state,
          prop.row.original.city,
          true
        )}
      </span>
    )
  }),
  columnHelper.accessor('roleExp', {
    id: 'roleExp',
    header: () => <ColumnSort title={'Total Role Exp'} />,
    size: 135,
    cell: (prop) => (
      <span class={'block p-4'}>
        {prop.getValue() !== null ? convertMonthsToYearsMonths(prop.getValue()) : 'N\\A'}
      </span>
    ),
    enableSorting: true
  }),
  columnHelper.accessor('careerExp', {
    id: 'careerExp',
    size: 110,
    header: () => <ColumnSort title={'Career Exp'} />,
    cell: (prop) => (
      <span class={'block p-4'}>
        {prop.getValue() !== null ? convertMonthsToYearsMonths(prop.getValue()) : 'N\\A'}
      </span>
    ),
    enableSorting: true
  }),
  columnHelper.display({
    id: 'view',
    size: 47,
    cell: (prop) => (
      <BaseLinkButton
        text={'View'}
        target={'_blank'}
        link={
          `/search-results/${store.lookalikeCurrentHistorySearchId}/candidate/${prop.row.original.candidateId}` +
          (store.shortlist.includes(prop.row.original.candidateProfileId)
            ? '?shortlisted=true'
            : '')
        }
      />
    )
  }),
  columnHelper.display({
    id: 'remove',
    size: 100,
    cell: (prop) => (
      <BaseButton
        text={'Remove'}
        bgColor={'red'}
        onClick={() => {
          emit('deleteShortList', prop.row.original.candidateProfileId)
        }}
      />
    )
  })
]

const tableConfig = ref({
  get data() {
    return props.candidateData
  },
  columns: columns,
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel()
})

const viewCandidate = (data) => {
  window.open(
    router.resolve({
      name: 'candidate',
      params: {
        searchId: store.lookalikeCurrentHistorySearchId,
        candidateId: data.original.candidateProfileId
      }
    }).href,
    '_blank'
  )
}

const table = ref(useVueTable(tableConfig.value))
const pageSize = ref(5)

table.value.setPageSize(pageSize.value)

const exportCsv = () => {
  const data = table.value.getRowModel().flatRows.map((row) => {
    return {
      Name: row.original.name,
      Position: row.original.position,
      Employer: row.original.employer,
      Industry: row.original.industry,
      Location: row.original.location,
      'Total Role Exp':
        row.original.roleExp !== null ? convertMonthsToYearsMonths(row.original.roleExp) : 'N\\A',
      'Career Exp':
        row.original.careerExp !== null
          ? convertMonthsToYearsMonths(row.original.careerExp)
          : 'N\\A',
      'Linkedin Profile URL': `https://www.linkedin.com/in/${row.original.candidateProfileId}`
    }
  })

  const csvConfig = mkConfig({
    useKeysAsHeaders: true,
    filename: `lookalikes_search_results_shortlisted_${props.archetypeName}_${new Date().toLocaleDateString()}`
  })
  const csv = generateCsv(csvConfig)(data)
  download(csvConfig)(csv)
}
</script>
